<template>
  <Layout class="ElectronicCommerce">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="search">
          <el-input
            v-model="formData.gameSourceId"
            clearable
            placeholder="游戏ID"
            @change="formData.gameSourceId = formData.gameSourceId.trim()"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>

    <div
      v-loading="statisticsLoading"
      class="overview"
    >
      <div class="items">
        <div class="name">
          待发货订单数量
        </div>
        <div class="num">
          {{ statisticsInfo.daifahuoNum | numberFilter }}  <span class="unit">个</span>
        </div>
      </div>
      <div class="items">
        <div class="name">
          总订单数
        </div>
        <div class="num">
          {{ statisticsInfo.totalNum | numberFilter }} <span class="unit">个</span>
        </div>
      </div>
      <div class="items">
        <div class="name">
          销售总金额
        </div>
        <div class="num">
          <span class="unit">$</span> {{ statisticsInfo.totalAmount | numberFilter }}
        </div>
      </div>
    </div>
    <el-form
      ref="form"
      :model="listInfo"
      size="small"
    >
      <el-table
        v-loading="listInfo.loading"
        :data="listInfo.list"
        stripe
        style="width: 100%"
        @sort-change="handleSortTable"
      >
        <el-table-column
          label="游戏ID"
          prop="sourceId"
          width="100px"
        />
        <el-table-column
          label="游戏名称"
          prop="gameName"
          width="180px"
        />
        <el-table-column
          label="标准价"
          prop="id"
          width="160px"
        >
          <template v-slot="{row, $index}">
            <template v-if="row.isEditing">
              <el-form-item
                :prop="`list.${$index}.normalPrice`"
                :rules="[
                  {required: true, message: '标准价必填'},
                  {validator: validatorMinPrice(row) }
                ]"
              >
                <el-input-number
                  v-model="row.normalPrice"
                  :precision="2"
                  :min="0"
                  placeholder="标准价"
                />
              </el-form-item>
            </template>
            <template v-else>
              $ {{ row.normalPrice !== undefined || null ? (row.normalPrice || 0).toFixed(2) : '/' }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="会员价"
          prop="id"
          width="160px"
        >
          <template v-slot="{row, $index}">
            <template v-if="row.isEditing">
              <el-form-item
                :prop="`list.${$index}.vipPrice`"
                :rules="[
                  {required: true, message: '会员价必填'},
                  {validator: validatorVipPrice(row) },
                  {validator: validatorMinPrice(row) }
                ]"
              >
                <el-input-number
                  v-model="row.vipPrice"
                  :precision="2"
                  :min="0"
                  placeholder="会员价"
                />
              </el-form-item>
            </template>
            <template v-else>
              $ {{ row.vipPrice !== undefined || null ? (row.vipPrice || 0).toFixed(2) : '/' }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="待发货订单数量"
          prop="daifahuo"
          sortable="custom"
          width="160px"
        />
        <el-table-column
          label="已发货订单数量"
          prop="yifahuo"
          sortable="custom"
          width="160px"
        />
        <el-table-column
          label="剩余库存量"
          prop="kucun"
          sortable="custom"
          width="160px"
        />
        <el-table-column
          label="是否销售"
        >
          <template v-slot="{row}">
            <template v-if="row.isEditing">
              <el-switch
                v-model="row.isSell"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
              />
            </template>
            <template v-else>
              {{ row.isSell !== undefined || null ? row.isSell === 1 ? '是' : '否' : '/' }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="封面"
          width="120px"
        >
          <template #default="{row}">
            <el-image
              style="width: 80px; height: 80px;"
              fit="contain"
              class="preview"
              :src="row.goodsImg"
            >
              <div
                slot="error"
                class="image-slot"
              >
                <i class="el-icon-picture-outline" />
              </div>
              <div
                slot="placeholder"
                class="image-slot"
              >
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          prop="id"
          width="300px"
          fixed="right"
        >
          <template v-slot="{row}">
            <el-button
              :loading="row.loading"
              type="text"
              @click="handleEditData(row)"
            >
              {{ row.isEditing ? '保存': '设置' }}
            </el-button>
            <el-button
              v-if="row.isEditing"
              type="text"
              style="color: gray;"
              @click="handleCancelEdit(row)"
            >
              取消设置
            </el-button>
            <el-button
              type="text"
              @click="openBatchUpload(row)"
            >
              补充库存
            </el-button>

            <el-button
              type="text"
              @click="openUploadCover(row)"
            >
              添加封面
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryGameList"
    />

    <!--    批量上传-->
    <el-dialog
      :visible.sync="batchUploadVisible"
      width="500px"
      @closed="loadedList = []"
    >
      <div class="batchUpload">
        <div class="icon-wrapper">
          <i class="el-icon-upload upload-icon" />
        </div>
        <template v-if="!loadedList.length">
          <div class="batchUpload-tips">
            <uploadExcel
              text="上传数据，仅支持模板格式的excel"
              @uploaded="handleUploadExcel"
            />
          </div>
          <div>
            <el-button
              type="text"
              @click="downloadExcel"
            >
              下载模板
            </el-button>
          </div>
        </template>
        <template v-else>
          <p>读取到 <span style="color: blue;">{{ loadedList.length }}</span> 条数据，是否确认上传</p>
          <div class="batchUpload-ctrl-area">
            <uploadExcel
              text="重新上传"
              @d="handleUploadExcel"
            />
            <el-button
              type="primary"
              @click="handlePostExcelData"
            >
              确定
            </el-button>
          </div>
        </template>
      </div>
    </el-dialog>
    <!--    批量上传-->
    <el-dialog
      :visible.sync="coverUploadVisible"
      width="500px"
      @closed="tempCoverUrl = ''"
    >
      <div class="coverUpload">
        <UploadImage2
          :show-close="true"
          preview-style="width: 150px; height: 150px;"
          :ratio="[1, 1]"
          :url.sync="tempCoverUrl"
        />
      </div>
      <div
        v-show="tempCoverUrl"
        class="ctrlWrapper"
      >
        <el-button @click="confirmUpload">
          确认上传
        </el-button>
      </div>
    </el-dialog>
  </Layout>
</template>

<script>
import { downloadExcel } from '@/components/UploadExcel/downloadExcel'
import uploadExcel from '@/components/UploadExcel/uploadExcel'
import { cloneDeep } from 'lodash'
import {
  queryOrderStatics,
  queryGoodsList,
  setSellInfo,
  updateCdkeys,
  updateImg
} from '@/api/mallv2.js'

export default {
  name: 'ElectronicCommerce',
  components: { uploadExcel },
  filters: {
    numberFilter (num) {
      if (num === undefined || num === null) {
        return '/'
      }
      return num
    }
  },
  data () {
    return {
      statisticsInfo: {},
      statisticsLoading: false,
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      editingMap: {},
      batchUploadVisible: false,
      loadedList: [],
      batchUploadTemplate: {
        list: [],
        header: ['CDKEY(必填)'],
        fields: ['cdkeys']
      },
      currentRows: {},
      coverUploadVisible: false,
      tempCoverUrl: ''
    }
  },
  created () {
    this.handleSearch()
    this.queryOrderStatics()
  },
  methods: {
    validatorVipPrice (row) {
      return function (rule, value, callback) {
        if (value > row.normalPrice) {
          callback(new Error('会员价不能高于标准价'))
        } else {
          callback()
        }
      }
    },
    validatorMinPrice (row) {
      return function (rule, value, callback) {
        if (value === 0) {
          callback(new Error('价格不可为0'))
        } else {
          callback()
        }
      }
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryGameList()
    },
    queryOrderStatics () {
      this.statisticsLoading = true
      queryOrderStatics({})
        .then(res => {
          if (res.code === 200) {
            this.statisticsInfo = res.data
          }
        })
        .finally(() => {
          this.statisticsLoading = false
        })
    },
    queryGameList () {
      const formData = this.formData
      this.listInfo.loading = true
      queryGoodsList(formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleSortTable ({ prop, order }) {
      this.formData.sortKey = prop
      const sortType = order === 'ascending' ? 'asc' : order === 'descending' ? 'desc' : ''
      this.formData.sortType = sortType
      if (sortType) {
        this.queryGameList()
      }
    },
    handleEditData (row) {
      if (row.isEditing) {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.$set(row, 'loading', true)
            const { isSell, gameIdStr, normalPrice, vipPrice } = row
            setSellInfo({
              isSell,
              gameIdStr,
              normalPrice,
              vipPrice
            }).then(res => {
              if (res.code === 200) {
                this.$message.success('更新成功')
                this.$set(row, 'isEditing', false)
              }
            }).finally(() => {
              this.$set(row, 'loading', false)
            })
          }
        })
      } else {
        this.editingMap[row.sourceId] = cloneDeep(row)
        this.$set(row, 'isEditing', true)
      }
    },
    handleCancelEdit (row) {
      this.$set(row, 'isEditing', false)
      Object.assign(row, this.editingMap[row.sourceId])
    },

    openBatchUpload (row) {
      this.batchUploadVisible = true
      this.currentRows = row
    },
    handlePostExcelData () {
      const cdkeys = this.loadedList || []
      const gameIdStr = this.currentRows.gameIdStr
      updateCdkeys({ cdkeys, gameIdStr })
        .then(res => {
          if (res.code === 200) {
            this.batchUploadVisible = false
            this.$message.success(`成功导入:${res.data.successNum || '/'} 条数据`)
            this.queryGameList()
          }
        })
    },
    downloadExcel () {
      const header = this.batchUploadTemplate.header
      const list = this.batchUploadTemplate.list
      const fields = this.batchUploadTemplate.fields
      downloadExcel(list, header, fields, 'CDKEY库存上传模板(填写数据从第二行开始, 请勿修改列名)')
    },
    uploadExcel (data) {
      const header = this.batchUploadTemplate.header
      const fields = this.batchUploadTemplate.fields
      const maps = header.reduce((result, field, index) => {
        result[field] = fields[index]
        return result
      }, {})
      return data.list.map(item => {
        const temp = {}
        Object.keys(item).forEach(field => {
          temp[maps[field]] = item[field]
        })
        return temp
      })
    },
    handleUploadExcel (data) {
      const result = this.uploadExcel(data) || []
      // check result list
      this.loadedList = result.map(item => {
        return item.cdkeys
      })
    },

    openUploadCover (row) {
      this.coverUploadVisible = true
      this.currentRows = row
    },
    confirmUpload () {
      const goodsImg = this.tempCoverUrl
      const row = this.currentRows
      updateImg({
        gameIdStr: row.gameIdStr,
        goodsImg
      })
        .then(res => {
          if (res.code === 200) {
            this.$message.success('上传成功')
            this.coverUploadVisible = false
            this.queryGameList()
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
  .ElectronicCommerce {
    .overview {
      display: flex;
      // padding: 0 0 20px 0;
      min-width: 880px;
      margin-bottom: 10px;
      .items {
        width: 210px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
          .unit {
            font-size: 14px;
          }
        }
        .name {
          font-size: 12px;
        }
      }
    }
    .batchUpload{
      text-align: center;
      .icon-wrapper{
        .upload-icon{
          font-size: 100px;
          color: @primary;
        }
      }
      .batchUpload-tips{
        margin: 20px 0 10px;
      }
      .batchUpload-ctrl-area{
        margin-top: 30px;
        text-align: right;
      }
    }
    .coverUpload {
      display: flex;
      justify-content: center;
    }
    .ctrlWrapper {
      display: flex;
      justify-content: center;
      margin-top: 40px;
    }
    .image-slot {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
</style>
