import request from '@/utils/request'

export function queryOrderList (data) {
  return request({
    url: '/mallv2/order/list',
    data
  })
}

export function queryOrderStatics (data) {
  return request({
    url: '/mallv2/order/statics',
    data
  })
}

export function queryGoodsList (data) {
  return request({
    url: '/mallv2/goods/list',
    data
  })
}

export function setSellInfo (data) {
  return request({
    url: '/mallv2/set/sellInfo',
    data
  })
}

export function updateCdkeys (data) {
  return request({
    url: '/mallv2/add/cdkeys',
    data
  })
}

export function updateImg (data) {
  return request({
    url: '/mallv2/goods/updateImg',
    data
  })
}

export function updateStatus (data) {
  return request({
    url: '/mallv2/updateStatus',
    data
  })
}
